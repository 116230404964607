<template>
  <div class="contact-us">
    <hero-page
      illustration="/img/contact-page-illustration.svg"
    >
      <template #title>
        <h1 :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 50vw;' : ''" class="white--text header font-weight-bold text-uppercase text-h4">
          Contact us and tell <br />
          us all your ideas
        </h1>
      </template>
    </hero-page>
    <div class="contact-form-wrapper">
        <alert-modal 
         v-model="alertModal"
         text="Message Sent. We will contact you soon!"
         type="success"
        />
      <h2 class="text-center">Get in Touch</h2>
      <alert-modal 
        v-model="alertModal"
        text="Message Sent. We will contact you soon!"
        type="success"
      />
      <v-container id="contact-us-form">
        <v-form
          ref="formRef"
          @submit.prevent="onSubmit"
          v-model="isFormValid"
          class="contact-form mt-5"
          data-aos="zoom-in"
        >
          <v-row class="justify-center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <v-select
                outlined
                hide-details="auto"
                label="Reason for Contact"
                placeholder="eg Inquiry"
                v-model="messagePayload.subject"
                :rules="[rules.required]"
                class="elevation-2"
                :items="subjects"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <v-text-field
                outlined
                hide-details="auto"
                placeholder="eg Jon Doe"
                label="Name"
                v-model="messagePayload.name"
                :rules="[rules.required]"
                class="elevation-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <v-text-field
                outlined
                hide-details="auto"
                placeholder="eg 5555555555"
                label="Phone Number (Optional)"
                v-model="messagePayload.phone"
                :rules="[rules.number]"
                class="elevation-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <v-text-field
                outlined
                hide-details="auto"
                placeholder="eg example@example.com"
                label="Email"
                v-model="messagePayload.email"
                :rules="[rules.required, rules.email]"
                class="elevation-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <v-text-field
                outlined
                hide-details="auto"
                label="Company (Optional)"
                v-model="messagePayload.company"
                class="elevation-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <v-textarea
                outlined
                hide-details="auto"
                placeholder="Your message..."
                label="Message"
                v-model="messagePayload.message"
                :rules="[rules.required]"
                class="elevation-2"
              />
            </v-col>
          </v-row>

          <recaptcha
            v-if="false"
            class="d-flex justify-center mt-6 mb-3"
            @on-captcha-verification="captchaWasVerified = true"
          />

          <div class="d-flex justify-center shrink my-6">
            <v-btn
              class="white--text"
              color="#2EB359"
              elevation="2"
              rounded
              x-large
              type="submit"
              :disabled="!isFormValid"
            >
              Send Message
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
import { mapActions } from "vuex";
import HeroPage from "@/components/ui/HeroPage.vue";
import { rules } from '@/plugins/validations'

export default {
  name: "ContactPage",
  components: {
    Recaptcha,
    HeroPage,
  },
  model: {
    event: "updateModel",
  },
  data() {
    return {
      alertModal: false,
      rules,
      isFormValid: false,
      messageWasSent: false,
      captchaWasVerified: false,
      subjects: [
        {
          text: "Web App Development",
          value: "web_app_development",
        },
        {
          text: "Mobile App Development IOS/Android",
          value: "ios_android_development",
        },
        {
          text: "Enterprise Software",
          value: "enterprise_software",
        },
        {
          text: "IoT",
          value: "iot",
        },
        {
          text: "Team Augmentation",
          value: "team_augmentation",
        },
        {
          text: "Partnership Proposal",
          value: "partnership_proposal",
        },
        {
          text: "DevOps",
          value: "devops",
        },
        {
          text: "Investor Relations",
          value: "investor_relations",
        },
      ],
      messagePayload: {
        company: "",
        subject: "",
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      contactMethods: [
        {
          title: "Email us",
          value: "support@mathisonprojects.com",
          link: "mailto:support@mathisonprojects.com",
          fn() {
            return;
          },
        },
        {
          title: "Call any time",
          value: "+1 (402) 212-3189",
          link: "tel:8322246504",
          fn() {
            return;
          },
        },
        {
          title: "Get in touch",
          value: "Leave a message",
          fn: () =>
            this.$refs["contactForm"].$el.scrollIntoView({
              behavior: "smooth",
            }),
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
  },
  mounted() {
    if (this.$route.hash === "#form") {
      this.scrollToContactForm();
    }
  },
  methods: {
    ...mapActions("contactUsStore", ["createContactUsForm"]),
    async onSubmit() {
      try {
        await this.createContactUsForm(this.messagePayload);
        this.$refs.formRef.reset()
        this.alertModal = true;
      } catch (error) {
        console.error(error);
      }
    },
    scrollToContactForm() {
      this.$vuetify.goTo("#contact-us-form", {
        offset: 100,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header{
  font-size: 2.5rem !important;
  text-transform: capitalize;
}
  .map {
    position: relative;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      // top: 50%;
      // left: 20%;
      background: #fff;
      z-index: 99;
      animation: highlight 3s linear infinite;

      &:hover {
        width: 12px;
        height: 12px;
        background: hotpink;
      }
    }

    @keyframes highlight {
      100% {
        transform: scale(3);
        opacity: 0;
      }
    }

    .marker-1 {
      top: 20%;
      left: 25%;
      animation-delay: 0.1s;
    }

    .marker-2 {
      top: 23%;
      left: 13%;
    }

    .marker-3 {
      top: 30%;
      left: 27%;
      animation-delay: 0.6s;
    }

    .marker-4 {
      top: 45%;
      left: 24%;
      animation-delay: 0.2s;
    }

    .marker-5 {
      bottom: 18%;
      left: 32%;
      animation-delay: 0.2s;
    }

    .marker-6 {
      bottom: 22%;
      left: 32%;
      animation-delay: 0.2s;
    }


    .marker-7 {
      bottom: 45%;
      left: 32%;
      animation-delay: .8s;
    }

    .marker-8 {
      top: 29%;
      right: 28%;
    }

    .marker-9 {
      top: 26%;
      left: 13%;
    }

    .marker-10 {
      top: 30%;
      right: 41%;
    }

    .marker-11 {
      bottom: 15%;
      left: 33%;
      animation-delay: 0.3s;
    }

    .marker-12 {
      top: 23%;
      left: 26%;
      animation-delay: 0.4s;
    }

    img {
      max-width: 100%;
      transform: scale(1);
    }
  }
.contact-us {
  overflow-x: hidden;
  h2 {
    margin: 0 auto;
    font-size: 2rem;
    max-width: 70vw;
  }

  .contact-items {
    max-width: 60rem;
    margin: 0 auto;
    .contact-item {
      display: flex;
      place-content: center start;
      width: 100%;
      overflow: hidden;

      a {
        text-decoration: none;
        color: currentColor;
      }

      span,
      a {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        place-content: center;
        font-weight: 700;
        margin: 0;
        width: 100%;
        word-wrap: break-word;
      }
    }
  }

  .contact-form-wrapper {
    overflow: hidden;
    // margin-top: 90vh;
  }

  .contact-info-wrapper {
    padding-left: 2rem;
    padding-bottom: 3rem;
    margin: 0 auto;
    width: 100%;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 2.5rem;
    }
    .contact-info-wrapper {
      padding-left: 5rem;
      padding-top: 3rem;
      margin: 0 auto;
      width: 100%;
    }
  }

  .lead {
    color: #2eb359;
    cursor: pointer;
  }

  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }

}
  .alert-container {
    display: none;
  }

  h2 {
    font-size: 2.5rem;
  }
</style>
